<template>
  <b-card>
    <b-row class="mb-2">
      <b-col cols="6" class="d-flex justify-content-start align-items-end">
        <b-img :src="emissaoImage" class="mr-1" />
        <div class="d-flex flex-column justify-content-start">
          <h4>
            {{ emissao.tipo_emissao }} - {{ emissao.nome_fantasia }}
          </h4>
          <span>{{ emissao.valor_global_emissao | moneyFormat }}</span>
        </div>
      </b-col>
      <b-col cols="6" class="d-flex flex-column justify-content-end align-items-end">
        <h4 class="d-flex align-items-end">
          Status:
          <b-badge
            :class="`ml-1 badge badge-light-${emissao.is_ativo ? 'success' : 'danger'} border-${
              emissao.is_ativo ? 'success' : 'danger'
            }`"
          >
            <span class="text-dark">
              {{ emissao.is_ativo ? 'Ativo' : 'Inativo' }}
            </span>
          </b-badge>
        </h4>
        <h4 class="mb-0 d-flex align-items-end">
          Valor da emissão:
          <h2 class="mb-0 ml-1">
            {{ emissao.valor_global_emissao | moneyFormat }}
          </h2>
        </h4>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <b-card class="emission-card-border emission-card-bg">
          <div class="d-flex justify-content-between">
            <span class="mb-75">Data da Emissão</span>
            <strong>{{ emissao.data_emissao | formatDate }}</strong>
          </div>
          <div class="d-flex justify-content-between">
            <span class="mb-75">Data do Vencimento</span>
            <strong>{{ emissao.data_vencimento | formatDate }}</strong>
          </div>
          <div class="d-flex justify-content-between">
            <span>Volume da série</span>
            <strong>{{ emissao.valor_global_emissao | moneyFormat }}</strong>
          </div>
          <hr />
          <div class="d-flex justify-content-between">
            <span class="mb-75">Emissão</span>
            <strong>{{ emissao.numero_emissao || '-' }}</strong>
          </div>
          <div class="d-flex justify-content-between">
            <span class="mb-75">Série</span>
            <strong>{{ emissao.numero_serie || '-' }}</strong>
          </div>
          <div class="d-flex justify-content-between">
            <span class="mb-75">Emissor</span>
            <strong>{{ emissao.emissor || '-' }}</strong>
          </div>
          <div class="d-flex justify-content-between">
            <span>Agente Fiduciário</span>
            <strong>{{ emissao.agente_fiduciario || '-' }}</strong>
          </div>
          <hr />
          <div class="d-flex justify-content-between">
            <span class="mb-75">Código IF</span>
            <strong>{{ emissao.codigo_cetip || '-' }}</strong>
          </div>
          <div class="d-flex justify-content-between">
            <span>ISIN</span>
            <strong>{{ emissao.codigo_isin || '-' }}</strong>
          </div>
        </b-card>
      </b-col>
      <b-col cols="6">
        <b-card class="emission-card-border emission-card-bg">
          <div class="d-flex justify-content-between">
            <span class="mb-75">
              Juros pagos
              {{
                emissao.data_ultimo_pagamento
                  ? `(${formatDate(emissao.data_ultimo_pagamento)})`
                  : ''
              }}
            </span>
            <strong>{{ emissao.juros_ultimo_pagamento | moneyFormat }}</strong>
          </div>
          <div class="d-flex justify-content-between">
            <span class="mb-75">Amortização</span>
            <strong>{{ emissao.amort_ultimo_pagamento | moneyFormat }}</strong>
          </div>
          <div class="d-flex justify-content-between">
            <span class="mb-75">Amortização Extraordinária</span>
            <strong>{{ emissao.amex_ultimo_pagamento | moneyFormat }}</strong>
          </div>
          <div class="d-flex justify-content-between">
            <span class="mb-75">Duration</span>
            <strong>{{ emissao.desc_duracao_remanescente || '-' }}</strong>
          </div>
          <div class="d-flex justify-content-between">
            <span class="mb-75">Período de Pagamentos de Juros</span>
            <strong>{{ emissao.periodo_pagamento_juros || '-' }}</strong>
          </div>
          <div class="d-flex justify-content-between">
            <span>Período de Amortizações</span>
            <strong>{{ emissao.periodo_pagamento_amort || '-' }}</strong>
          </div>
          <hr />
          <div class="d-flex justify-content-between" v-if="emissao.data_carencia == null">
            <span class="mb-75">Liquidação do último pagamento</span>
            <strong>{{ emissao.data_ultimo_pagamento | formatDate }}</strong>
          </div>
          <div class="d-flex justify-content-between" v-if="emissao.data_carencia == null">
            <span>Próximo pagamento</span>
            <strong>{{ emissao.data_proximo_pagamento | formatDate }}</strong>
          </div>
          <div class="d-flex justify-content-between" v-if="emissao.data_carencia != null">
            <span>Carência</span>
            <strong>até {{ emissao.data_carencia | formatDate }}</strong>
          </div>

          <hr />
          <div class="d-flex justify-content-between">
            <span>Remuneração</span>
            <strong>{{ emissao.remuneracao || '-' }}</strong>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="emissao.data_carencia == null">
      <b-col cols="12">
        <b-card>
          <h4 class="font-weight-bolder">Histórico de pagamentos</h4>
          <vue-apex-charts
            ref="paymentHistory"
            height="200"
            :options="chartOptions"
            :series="series"
          />
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-card>
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex flex-column">
              <h4 class="font-weight-bolder">PUs Históricos</h4>
              <span>Selecione o período para baixar.</span>
            </div>
            <div class="d-flex text-nowrap">
              <div class="input-icon">
                <flat-pickr
                  v-model="initialDate"
                  class="form-control"
                  :config="{ dateFormat: 'd/m/Y', maxDate: 'today' }"
                />
                <feather-icon icon="CalendarIcon" size="22" class="icon-position text-success" />
              </div>
              <div class="input-icon ml-1">
                <flat-pickr
                  v-model="finalDate"
                  class="form-control"
                  :config="{ dateFormat: 'd/m/Y', maxDate: 'today' }"
                />
                <feather-icon icon="CalendarIcon" size="22" class="icon-position text-success" />
              </div>
              <b-button variant="outline-warning" class="ml-1" @click="downloadExcel">
                <span class="text-secondary">Baixar excel</span>
              </b-button>
              <b-button variant="outline-warning" class="ml-1" @click="openPusGraphModal">
                <span class="text-secondary">Abrir gráfico</span>
              </b-button>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="emissao">
      <b-col cols="12">
        <b-card class="mb-1">
          <h4 class="font-weight-bolder">Calculadora CREDITSEC</h4>
          <hr />
          <div class="d-flex justify-content-between align-items-center">
            <b-button-group class="mb-2 text-nowrap mr-3">
              <b-button
                :variant="selectedButtonVariant.price"
                class="text-dark"
                @click="selectButton('price')"
              >
                <strong>Preço</strong>
              </b-button>
              <b-button
                :variant="selectedButtonVariant.tax"
                class="text-dark"
                @click="selectButton('tax')"
              >
                <strong>Taxas</strong>
              </b-button>
            </b-button-group>
            <b-form-checkbox v-model="useTerms">
              <span class="mr-2">
                Concordo com os
                <a :href="useTermsLink" target="_blank" class="h5 text-secondary mb-0">
                  Termos de uso e condições
                </a>
              </span>
            </b-form-checkbox>
          </div>
          <div class="d-flex justify-content-between align-items-end text-nowrap">
            <b-form-group label="Código IF" label-for="if_b3" class="mb-0 max-w-100">
              <b-form-input v-model="emissao.codigo_cetip" name="if_b3" readonly />
            </b-form-group>
            <b-form-group label="Data do Cálculo" label-for="calcDate" class="mb-0 ml-1">
              <div class="input-icon">
                <flat-pickr
                  v-model="calcDate"
                  class="form-control"
                  :config="{ dateFormat: 'd/m/Y' }"
                />
                <feather-icon icon="CalendarIcon" size="22" class="icon-position text-success" />
              </div>
            </b-form-group>
            <div
              v-if="selectedButton === 'price'"
              class="d-flex justify-content-end align-items-end ml-1"
            >
              <b-form-group label="Taxa" label-for="tax" class="mb-0">
                <v-select v-model="tax" :options="taxMenuOptions()" :clearable="false" />
              </b-form-group>
              <feather-icon icon="PlusIcon" size="22" class="mb-75 mr-1 ml-1" />
              <b-form-group class="mb-0 max-w-6">
                <money v-model="taxValue" class="form-control" v-bind="percentage" maxlength="7" />
              </b-form-group>
            </div>
            <div v-else class="ml-1">
              <b-form-group class="mb-0" label="PU" label-for="moneyValue">
                <money v-model="moneyValue" class="form-control" />
              </b-form-group>
            </div>
            <feather-icon icon="PauseIcon" size="22" class="rotate-90 mb-75 mr-1 ml-1" />

            <b-form-group class="mb-0" label="PU Estimado" label-for="result"  v-if="selectedButton === 'price'">
              <b-form-input v-model="result" name="result" disabled />
            </b-form-group>

            <b-form-group class="mb-0" label="Taxa Estimada" label-for="taxaEstimada" v-if="selectedButton === 'tax'">
              <b-form-input v-model="taxaEstimada" name="taxaEstimada" disabled />
            </b-form-group>



            <b-button
              variant="primary"
              class="text-dark ml-1"
              @click="calculateTax"
              :disabled="!canCalculateTax"
            >
              <strong>Calcular</strong>
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-for="(doc, index) in docs" :key="index">
      <b-col cols="12">
        <b-card-actions
          v-if="doc.documentos.length"
          class="mt-2"
          :title="doc.tipo"
          action-collapse
          :collapsed="index !== 0"
        >
          <div class="border" v-if="doc.documentos.length">
            <b-table
              responsive="sm"
              :items="doc.documentos"
              :fields="docFields"
              class="mb-0 pus-table"
            >
              <template #cell(url)="data">
                <a :href="data.item.url" target="_blank">
                  <feather-icon
                    icon="DownloadIcon"
                    size="22"
                    class="text-success"
                    @click="downloadFile(doc)"
                  />
                </a>
              </template>
            </b-table>
          </div>
          <div class="border-top pt-2" v-else>
            <span class="text-center">
              <feather-icon icon="AlertCircleIcon" size="20" class="text-muted mr-50" />
              Não há documentos disponíveis no momento.
            </span>
          </div>
        </b-card-actions>
      </b-col>
    </b-row>

    <b-modal id="pusModal" centered hide-footer header-bg-variant="white" size="lg">
      <span>Características</span>
      <h4 class="font-weight-bolder">PUs Históricos</h4>
      <span>{{ emissao.codigo_isin }} / {{ emissao.nome_emissao }} / {{ emissao.nome_serie }}</span>
      <vue-apex-charts
        ref="pus"
        height="400"
        :options="pusGraph.chartOptions"
        :series="pusGraph.series"
      />
    </b-modal>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BImg,
  BCard,
  BTable,
  BBadge,
  BFormInput,
  BFormGroup,
  BButton,
  BButtonGroup,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { BCardActions } from '@/@core/components/b-card-actions'
import VueApexCharts from 'vue-apexcharts'
import { moneyFormat } from '@/@core/comp-functions/data_visualization/datatable'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-cycle
import { formatDate } from '@core/utils/filter'
import { downloadFromResponse } from '@/@core/comp-functions/forms/cc-dropzone'

export default {
  name: 'Emissoes',
  components: {
    BCardActions,
    BTable,
    BRow,
    BCol,
    BImg,
    BCard,
    BBadge,
    BButton,
    vSelect,
    flatPickr,
    BFormGroup,
    BFormInput,
    BButtonGroup,
    VueApexCharts,
    BFormCheckbox,
  },
  filters: {
    moneyFormat,
    formatDate,
  },
  data() {
    return {
      useTerms: false,
      useTermsLink:
        'https://policies-creditcorp-public.s3.sa-east-1.amazonaws.com/politica_privacidade.pdf',
      result: '',
      taxaEstimada: '',
      taxValue: '',
      moneyValue: '',
      calcDate: '',
      selectedButton: 'price',
      pusGraph: {
        series: [],
        chartOptions: {
          chart: {
            type: 'area',
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            curve: 'straight',
          },
          legend: {
            show: true,
            position: 'top',
            horizontalAlign: 'left',
            fontSize: '14px',
            fontFamily: 'Lato Black',
            markers: {
              radius: 12,
            },
          },
          grid: {
            show: true,
            row: {
              colors: ['transparent'],
            },
            column: {
              colors: ['transparent'],
            },
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            labels: {
              show: true,
              align: 'right',
              minWidth: 0,
              maxWidth: 160,
              style: {
                colors: [],
                fontSize: '12px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 400,
                cssClass: 'apexcharts-yaxis-label',
              },
              offsetX: 0,
              offsetY: 0,
              rotate: 0,
              formatter: (value) => moneyFormat(value),
            },
          },
          tooltip: {
            shared: false,
          },
          colors: ['#3FE4A9'],
        },
      },
      if_b3: '',
      tax: {
        label: null,
        value: null,
      },
      items: [
        {
          data: '28/02/2023',
          arquivo: 'Demonstração do patrimônio separado 06/2022',
          download: 'Macdonald',
        },
      ],
      initialDate: '',
      finalDate: '',
      emissao: {},
      series: [],
      chartOptions: {
        chart: {
          type: 'bar',
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          curve: 'straight',
        },
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'left',
          fontSize: '14px',
          fontFamily: 'Montserrat',
          markers: {
            radius: 12,
          },
        },
        grid: {
          show: true,
          row: {
            colors: ['transparent'],
          },
          column: {
            colors: ['transparent'],
          },
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          labels: {
            show: true,
            align: 'right',
            minWidth: 0,
            maxWidth: 160,
            style: {
              colors: [],
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-yaxis-label',
            },
            offsetX: 0,
            offsetY: 0,
            rotate: 0,
            formatter: (value) => moneyFormat(value),
          },
        },
        tooltip: {
          shared: false,
        },
        colors: ['#E3C092', '#C9794A', '#3FE4A9'],
      },
      percentage: {
        max: 100,
        suffix: '%',
        prefix: '',
        precision: 2,
      },
      docs: [],
      docFields: [
        {
          key: 'data_documento',
          label: 'Data',
          formatter: (value) => formatDate(value),
          tdClass: 'wd-10',
        },
        {
          key: 'name',
          label: 'Arquivo',
        },
        {
          key: 'url',
          label: 'Download',
          thClass: 'wd-5',
        },
      ],
    }
  },
  async mounted() {
    const { data } = await this.$store.dispatch(
      'publicStore/getEmission',
      this.$route.params.serie_id,
    )
    this.emissao = data.data
    this.loadPaymentHistoryGraph()

    await this.getDocs()
  },
  methods: {
    async loadPaymentHistoryGraph() {
      if (!this.$refs.paymentHistory) return

      const paymentHistory = await this.$store.dispatch(
        'publicStore/paymentHistory',
        this.$route.params.serie_id,
      )
      this.series = paymentHistory.data.data.data
      this.chartOptions.xaxis.categories = paymentHistory.data.data.categories
      this.$refs.paymentHistory?.updateOptions({ ...this.chartOptions })
    },
    formatDate,
    taxMenuOptions() {
      return [
        {
          label: this.emissao?.tipo_remuneracao ? `${this.emissao.tipo_remuneracao.toUpperCase()} + X%` : '-',
          value: 'taxa',
        },
        {
          label: 'X% do valor na data',
          value: 'percentual',
        },
      ]
    },
    async getDocs() {
      const { data } = await this.$store.dispatch(
        'publicStore/getDocs',
        this.$route.params.serie_id,
      )

      this.docs = data.data
    },
    async calculateTax() {
      this.$swal({
        title: 'Calculando...',
        onOpen: () => {
          this.$swal.showLoading()
        },
        allowOutsideClick: false,
      })
      try {
        let payload = {
          id: this.$route.params.serie_id,
          data: this.calcDate,
          tipo: this.paramTipoCalculadora(),
        }

        if (this.selectedButton === 'tax') {
          payload.valor = this.moneyValue
        } else {
          payload.valor = this.taxValue
        }

        const { data } = await this.$store.dispatch('publicStore/calcDesagio', payload)


        if (this.selectedButton === 'price') {
          this.result = moneyFormat(data.PrevisaoTotalPorCota)
        }

        if (this.selectedButton === 'tax') {
          var taxa = data.TaxaNova

          if (data.TaxaNova.startsWith('IPCA/IBGE')) {
            taxa = taxa.replace('IPCA/IBGE', 'IPCA')
          } else if (data.TaxaNova.startsWith('Taxa DI')) {
            taxa = taxa.replace('Taxa DI', 'CDI')
          }

          if (taxa.includes(' + -')) {
            taxa = taxa.replace(' + -', ' - ')
          }

          this.taxaEstimada = taxa
        }

      } finally {
        this.$swal.close()
      }
    },
    selectButton(type) {
      this.result = ''
      this.selectedButton = type
    },
    async downloadExcel() {
      this.$swal({
        title: 'Carregando dados...',
        onOpen: () => {
          this.$swal.showLoading()
        },
        allowOutsideClick: false,
      })
      try {
        const { data } = await this.$store.dispatch('publicStore/downloadPusHistory', {
          id: this.$route.params.serie_id,
          data_inicial: this.initialDate,
          data_final: this.finalDate,
        })
        downloadFromResponse(data, {
          mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          fileName: 'pus.xlsx',
        })
        this.$swal.close()
      } catch (error) {
        const reader = new FileReader()
        reader.onload = () => {
          const errorText = JSON.parse(reader.result).errors.join(' ')
          this.$swal.fire({
            title: 'Erro!',
            text: errorText,
            icon: 'error',
          })
        }
        reader.readAsText(error.response.data)
      }
    },
    async openPusGraphModal() {
      this.$bvModal.show('pusModal')
      this.$swal({
        title: 'Carregando dados...',
        onOpen: () => {
          this.$swal.showLoading()
        },
        allowOutsideClick: false,
      })

      const { data } = await this.$store.dispatch('publicStore/pusGraph', {
        id: this.$route.params.serie_id,
        data_inicial: this.initialDate,
        data_final: this.finalDate,
      })
      this.$refs.pus.updateSeries([{ name: 'Valor', data: data.data.data }])
      this.$refs.pus?.updateOptions({
        xaxis: {
          categories: data.data.categories,
        },
      })
      this.$swal.close()
    },

    paramTipoCalculadora() {
      if (this.selectedButton === 'tax') {
        return '6'
      }

      if (this.tax === 'percentual') {
        return '5'
      }

      if (this.emissao.tipo_remuneracao === 'cdi') {
        return '1'
      }

      if (this.emissao.tipo_remuneracao === 'ipca') {
        return '2'
      }

      return '0'
    },
  },
  computed: {
    canCalculateTax() {
      return this.selectedButton === 'tax'
        ? this.calcDate && this.moneyValue && this.useTerms
        : this.calcDate && this.taxValue && this.useTerms
    },
    selectedButtonVariant() {
      const types = {
        price: 'outline-primary',
        tax: 'outline-primary',
      }

      types[this.selectedButton] = 'primary'

      return types
    },

    emissaoImage() {
      const options = {
        cri: 'cri',
        cra: 'cra',
        deb: 'debenture',
        nc: 'nota_comercial',
      }

      // eslint-disable-next-line global-require, import/no-unresolved
      if (!this.emissao.tipo_emissao || !options[this.emissao.tipo_emissao.toLowerCase()]){
        return require('@/assets/images/recebíveis.svg')
      }

      // eslint-disable-next-line import/no-dynamic-require, global-require
      return require(`@/assets/images/${options[this.emissao?.tipo_emissao?.toLowerCase()]}.svg`)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.max-w-6 {
  max-width: 6rem;
}
.max-w-10 {
  max-width: 10rem;
}

.emissoes-details-button {
  background-color: #3fe4c6 !important;
  border: 1px solid #1eb499 !important;
}

.btn-primary {
  color: #000000 !important;
}

.cs-select .vs__dropdown-toggle {
  border-radius: 0.357rem 0 0 0.357rem;
}

.cs-select .vs--open .vs__dropdown-toggle {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.input-icon {
  position: relative;
  display: inline-block;
}

.icon-position {
  position: absolute;
  right: 10px;
  top: 8px;
}

.pus-table th {
  background-color: #eff4f6;
  color: #242a32;
}

.emission-card-border {
  border-radius: 8px;
  border: 1px solid #becad4;
}

.emission-card-bg {
  border-radius: 8px;
  border: 1px solid #becad4;
  background: #f8fafc;
}
</style>
